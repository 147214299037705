<template>
  <form @submit.prevent="loadDashboard" class="d-flex flex-column justify-content-center my-3">
    <h4 class="px-2">Welcome to IntelliPath Pro!</h4>
    <div class="col my-4">
      <label for="labSelector"><b>Select Lab: </b></label>
      <select-input
        v-focus
        data-testid="labSelector"
        data-cy="labSelector"
        name="labSelector"
        id="labSelector"
        ref="selector"
        v-model="selectedLabId"
        required
        :items="availableLabs"
        searchExpr="longDisplayName"
      >
      </select-input>
    </div>
    <div class="my-2 ml-auto">
      <loader size="small" v-if="isLoading"></loader>
      <button type="submit" class="btn btn-primary">Confirm</button>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "@/components/common/Loader.vue";
import SelectInput from "@/components/common/SelectInput.vue";
export default {
  components: { Loader, SelectInput },
  name: "LabSelector",
  data() {
    return {
      selectedLabId: null,
      isLoading: false
    };
  },

  domStreams: ["submit$", "pinRequest$"],
  computed: {
    ...mapState(["currentUser", "currentLab", "availableLabs"]),
    labId: {
      get() {
        return this.currentLab;
      },
      set(value) {
        return value;
      }
    }
  },
  methods: {
    ...mapActions(["authenticate", "setCurrentLab"]),
    async loadDashboard() {
      this.isLoading = true;
      await this.$store.dispatch("setCurrentLab", this.selectedLabId);
      this.isLoading = false;
      if (this.$route.name !== "Home") {
        this.$router.push("/"); //Useful for assuring everything is reloaded and the user is navigated back to the dashboard.
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.modal-heading {
  margin-top: 20px;
  margin-left: 10px;
}

::v-deep fa-times {
  opacity: 0;
}
</style>
