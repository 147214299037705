<template>
  <div class="container-fluid">
    <div class="form p-4">
      <router-link to="/login">
        <img class="ip_logo text-center" src="@/assets/logo.svg" />
      </router-link>
      <router-view />

      <modal
        class="hide_close"
        :status="mustSelectLab && !userMustProvidePin && !userMustChangePassword"
      >
        <lab-selector />
      </modal>
    </div>
  </div>
</template>

<script>
import Modal from "@/components/common/Modal.vue";
import { mapGetters, mapState } from "vuex";
import LabSelector from "./LabSelector.vue";
export default {
  components: { Modal, LabSelector },
  computed: {
    ...mapGetters(["mustSelectLab"]),
    ...mapState({
      userMustProvidePin: state => state.currentUser.userMustProvidePin,
      userMustChangePassword: state => state.currentUser.userMustChangePassword
    })
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: #047ead;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.form {
  width: 350px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #b3d8e6;
  form {
    width: 100%;
  }
}
.container {
  background-color: $primary-light;
}
label {
  font-weight: 600;
  color: black;
}
.ip_logo {
  align-self: center;
  height: 80px;
  width: 80px;
  border-radius: 5px;
}
.error {
  height: 55px;
  font-weight: 600;
}
</style>
